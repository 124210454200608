import React, { useState, useRef } from "react";
import { motion, useAnimation } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaEthereum, FaTelegram, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { RiTwitterXLine,  } from "react-icons/ri";
import { BsPlayFill,  } from "react-icons/bs";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet, AiFillFile, AiFillStar } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import AOS from 'aos';
import 'aos/dist/aos.css'


const Hero = ({ pryColor
    , secColor }
) => {


   

    const buttonStyle = "text-[20px] md:text-[22px] bg-[#fff] text-[#58873F] p-2 rounded-full"
    const containerVariants = {
        hidden: { scale: 0, opacity: 0 },
        visible: { scale: 1, opacity: 1, transition: { type: 'spring', stiffness: 260, damping: 20 } },
      };
    const imgVariants = {
        hidden: { scale: 0, opacity: 0 },
        visible: { scale: 1, opacity: 1, transition: { type: 'spring', stiffness: 260, damping: 20, delay:0.5 } },
      };
      const controls = useAnimation();
      React.useEffect(() => {
        controls.start('visible');
      }, [controls]);

    return (<div className=" md:h-screen relative flex flex-col items-center justify-center gap-5 w-[90%] text-center mx-auto text-[#000]">

                <div className="w-full md:h-[30%] py-8 md:py-0 flex-col flex items-center justify-center gap-1">
                    <h1 className="text-[35px] md:text-[95px] text-white  font-bold">ape on solana</h1>
                    <p className="uppercase font-bold text-[#343538]">powered by solana</p>
                </div>
                
                <div className="md:h-[70%] justify-center w-full relative items-center flex flex-col-reverse md:flex-row">
                    <motion.img src="../img/logo-1.png" alt="logo" className=" w-full md:w-[40%] "
                           initial="hidden"
                           animate={controls}
                           variants={imgVariants}
                           
                    />       
                    <motion.div className="w-full md:w-1/2 text-[30px] justify-center gap-10 items-center uppercase flex flex-col" 
                         initial="hidden"
                         animate={controls}
                         variants={containerVariants}
                    >
                        <p className="w-[75%] bg-white rotate-6 hover:rotate-0 duration-200 p-5 md:w-[60%] text-[#343538]"><span className="text-[#A100FF] ">$APE</span> is not your typical meme coin. it's here to reclaim its throne on the Solana blockchain. Ape it, love it</p>
                        <div className="w-full gap-10 flex flex-row items-center justify-center">
                                <a href="https://raydium.io/swap?outputCurrency=667m2vLTouoM5u1PqLTJV2YUNyUjqdXrbCVpTBBbEFra"><button className=" border-[4px] text-[20px] border-[#343538] p-3 text-white font-bold bg-[#A100FF] uppercase  rotate-6">$ape it</button></a>
                                <div className="flex flex-row gap-3">
                                    <a href="https://t.me/apeonsolana"><button className=" bg-[#343538] flex flex-row justify-center items-center w-[50px] h-[50px] text-white text-[30px] -rotate-6"><FaTelegram /></button></a>
                                    <a href="https://x.com/apeonsolanafun"><button className=" bg-[#343538] flex flex-row justify-center items-center w-[50px] h-[50px] text-white text-[30px] rotate-6"><RiTwitterXLine /></button></a>
                                </div>
                        </div>
                    </motion.div>

                </div>

    </div>);
}

export default Hero;